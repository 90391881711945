<script>
import Layout from '../../layouts/main';
import { api } from '@/state/services';
import { mapState } from 'vuex';
import { VueContentLoading } from 'vue-content-loading';
import Binary from '@/components/widgets/binary';

export default {
  locales: {
    pt: {
      'Binary': 'Binário',
      'Left Side': 'Lado Esquerdo',
      'Right Side': 'Lado Direito',
      'CURRENT': 'ATUAL',
      'TOTAL': 'TOTAL',
      'TEAM': 'EQUIPE',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Binary': 'Binario',
      'Left Side': 'Lado Izquierdo',
      'Right Side': 'Lado Derecho',
      'CURRENT': 'ACTUAL',
      'TOTAL': 'TOTAL',
      'TEAM': 'EQUIPO',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, VueContentLoading, Binary },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      loading: true,
      errored: false,

      id: this.$route.params.id,
      username: null,
      name: null,

      left: {
        current: '0',
        total: '0',
        users: '0',
      },
      right: {
        current: '0',
        total: '0',
        users: '0',
      },

      tree: {
        left: {
          c1: { l1: false },
          c2: { l1: false, l2: false },
          c3: { l1: false, l2: false, l3: false, l4: false },
          c4: { l1: false, l2: false, l3: false, l4: false, l5: false, l6: false, l7: false, l8: false },
        },
        right: {
          c1: { l1: false },
          c2: { l1: false, l2: false },
          c3: { l1: false, l2: false, l3: false, l4: false },
          c4: { l1: false, l2: false, l3: false, l4: false, l5: false, l6: false, l7: false, l8: false },
        }
      },

      upline: {
        left: 0,
        right: 0,
      },

      final: {
        left: 0,
        right: 0,
      }
    }
  },
  methods: {
    getBinary() {
      if (this.id) {
        api
          .get('network/binary/' + this.id)
          .then(response => {
            if (response.data.status=='success') {
              this.left.current = response.data.points.current.left
              this.left.total = response.data.points.total.left
              this.left.users = response.data.users.left

              this.right.current = response.data.points.current.right
              this.right.total = response.data.points.total.right
              this.right.users = response.data.users.right

              this.username = response.data.user.username
              this.name = response.data.user.name

              this.tree = response.data.tree
              this.upline = response.data.upline
              this.final = response.data.final
            }
          })
          .catch(error => {
            this.errored = error
            this.loading = false
          })
          .finally(() => {
            this.errored = false
            this.loading = false
          })
      }
    },
    setBinary(id) {
      this.id = id
      this.loading = true
      this.getBinary()
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    } else {
      this.id = this.account.user.id
    }

    this.getBinary();
  },
  watch: {
    '$route.params.id': function (id) {
      if (id) {
        this.loading = true
        this.id = id
      } else {
        this.id = this.account.user.id
      }

      this.getBinary()
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Binary') }}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="bg-white mb-4 pt-3 pl-3 pr-3">
          <div class="row">
            <div class="col-md-12 text-center">
              {{ t('Left Side') }}
              <hr>
            </div>
            <div class="col">
              <div class="mb-3 p-1 pt-2 text-center">
                <h5 class="m-0 notranslate" style="line-height:12px;">{{ left.current }}</h5>
                <small>{{ t('CURRENT') }}</small>
              </div>
            </div>
            <div class="col">
              <div class="mb-3 p-1 pt-2 text-center">
                <h5 class="m-0 notranslate" style="line-height:12px;">{{ left.total }}</h5>
                <small>{{ t('TOTAL') }}</small>
              </div>
            </div>
            <div class="col">
               <div class="mb-3 p-1 pt-2 text-center">
                <h5 class="m-0 notranslate" style="line-height:12px;">{{ left.users }}</h5>
                <small>{{ t('TEAM') }}</small>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="bg-white mb-4 pt-3 pl-3 pr-3">
          <div class="row">
            <div class="col-md-12 text-center">
              {{ t('Right Side') }}
              <hr>
            </div>
            <div class="col">
              <div class="mb-3 p-1 pt-2 text-center">
                <h5 class="m-0 notranslate" style="line-height:12px;">{{ right.current }}</h5>
                <small>{{ t('CURRENT') }}</small>
              </div>
            </div>
            <div class="col">
              <div class="mb-3 p-1 pt-2 text-center">
                <h5 class="m-0 notranslate" style="line-height:12px;">{{ right.total }}</h5>
                <small>{{ t('TOTAL') }}</small>
              </div>
            </div>
            <div class="col">
              <div class="mb-3 p-1 pt-2 text-center">
                <h5 class="m-0 notranslate" style="line-height:12px;">{{ right.users }}</h5>
                <small>{{ t('TEAM') }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body table-responsive">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else id="binary" class="table-responsive">
              <table id="tree" class="table" style="margin:0;">
                <tr class="a1">
                  <td colspan="11">
                    <router-link :to="'/network/binary/' + final.left" class="btn btn-danger mr-2">FINAL</router-link>
                    <router-link :to="'/network/binary/' + upline.left" class="btn btn-danger">UP</router-link>
                  </td>
                  <td colspan="10" class="team-item">
                    <div v-if="loading"><template><vue-content-loading :width="20" :height="20"><circle cx="10" cy="10" r="10" /></vue-content-loading></template></div>
                    <div v-else><img src="@/assets/images/logo-icon.png"><span>{{ username }}</span></div>
                  </td>
                  <td colspan="11">
                    <router-link :to="'/network/binary/' + upline.right" class="btn btn-danger mr-2">UP</router-link>
                    <router-link :to="'/network/binary/' + final.right" class="btn btn-danger">FINAL</router-link>
                  </td>
                </tr>
                <tr class="a2">
                  <td colspan="8"></td>
                  <td colspan="8" class="aleft"></td>
                  <td colspan="8" class="aright"></td>
                  <td colspan="8"></td>
                </tr>
                <tr class="a2">
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                </tr>
                <tr>
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                  <td colspan="8" class="bleft"></td>
                  <td colspan="8"></td>
                </tr>
                <tr class="a1">
                  <td colspan="16" class="team-item"><Binary :loading="loading" :tree="tree.left.c1.l1" /></td>
                  <td colspan="16" class="team-item"><Binary :loading="loading" :tree="tree.right.c1.l1" /></td>
                </tr>
                <tr class="a3">
                  <td colspan="4"></td>
                  <td colspan="4" class="aleft"></td>
                  <td colspan="4" class="aright"></td>
                  <td colspan="4"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="aleft"></td>
                  <td colspan="4" class="aright"></td>
                  <td colspan="4"></td>
                </tr>
                <tr class="a3">
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                  <td colspan="4" class="bleft"></td>
                  <td colspan="4"></td>
                </tr>
                <tr class="a2">
                  <td colspan="8" class="team-item"><Binary :loading="loading" :tree="tree.left.c2.l1" /></td>
                  <td colspan="8" class="team-item"><Binary :loading="loading" :tree="tree.left.c2.l2" /></td>
                  <td colspan="8" class="team-item"><Binary :loading="loading" :tree="tree.right.c2.l1" /></td>
                  <td colspan="8" class="team-item"><Binary :loading="loading" :tree="tree.right.c2.l2" /></td>
                </tr>
                <tr class="a4">
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="aleft"></td>
                  <td colspan="2" class="aright"></td>
                  <td colspan="2"></td>
                </tr>
                <tr class="a4">
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                  <td colspan="2" class="bleft"></td>
                  <td colspan="2"></td>
                </tr>
                <tr class="a3">
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.left.c3.l1" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.left.c3.l2" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.left.c3.l3" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.left.c3.l4" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.right.c3.l1" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.right.c3.l2" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.right.c3.l3" /></td>
                  <td colspan="4" class="team-item"><Binary :loading="loading" :tree="tree.right.c3.l4" /></td>
                </tr>
                <tr class="a5">
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="aleft"></td>
                  <td colspan="1" class="aright"></td>
                  <td colspan="1"></td>
                </tr>
                <tr class="a5">
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                  <td colspan="1" class="bleft"></td>
                  <td colspan="1"></td>
                </tr>
                <tr class="a4">
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l1" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l2" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l3" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l4" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l5" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l6" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l7" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.left.c4.l8" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l1" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l2" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l3" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l4" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l5" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l6" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l7" /></td>
                  <td colspan="2" class="team-item"><Binary :loading="loading" :tree="tree.right.c4.l8" /></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>