<script>
import { VueContentLoading } from "vue-content-loading";

export default {
  components: { VueContentLoading },
  props: {
    loading: {
      default: true
    },
    tree: {
      default: {
        id: null,
        username: null
      }
    }
  }
};
</script>

<template>
  <div v-if="loading"><template><vue-content-loading :width="20" :height="20"><circle cx="10" cy="10" r="10" /></vue-content-loading></template></div>
  <div v-else>
    <div v-if="tree">
      <router-link :to="'/network/binary/'+tree.id">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.25 86.25"><polygon fill="#FF151F" points="24.2 40.56 0 16.36 16.36 0 40.56 24.2 37.5 37.5 24.2 40.56"/><polygon fill="#FF151F" points="45.69 24.2 69.89 0 86.25 16.36 62.05 40.56 48.97 37.28 45.69 24.2"/><polygon fill="#FF151F" points="40.56 62.05 16.36 86.25 0 69.89 24.2 45.69 37.25 49 40.56 62.05"/><path fill="#FF151F" d="M61.73,46.79l23.11,23.1-15,15L46.79,61.73l2.9-12,12-2.9m.32-1.1L48.86,48.86,45.69,62.05l24.2,24.2L86.25,69.89l-24.2-24.2Z"/></svg>
        <span class="notranslate">{{ tree.username }}</span>
      </router-link>
    </div>
    <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86.25 86.25"><polygon fill="#ccc" points="24.2 40.56 0 16.36 16.36 0 40.56 24.2 37.5 37.5 24.2 40.56"/><polygon fill="#ccc" points="45.69 24.2 69.89 0 86.25 16.36 62.05 40.56 48.97 37.28 45.69 24.2"/><polygon fill="#ccc" points="40.56 62.05 16.36 86.25 0 69.89 24.2 45.69 37.25 49 40.56 62.05"/><path fill="#ccc" d="M61.73,46.79l23.11,23.1-15,15L46.79,61.73l2.9-12,12-2.9m.32-1.1L48.86,48.86,45.69,62.05l24.2,24.2L86.25,69.89l-24.2-24.2Z"/></svg>
  </div>
</template>